<template>
    <section class="section about-section gray-bg" id="about">
            <div class="px-5">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-12">
                        <div class="about-text go-to">
                            <h3 class="dark-color"><i class="fa-solid fa-user pr-3"></i>Profile</h3>
                            <div class="row about-list">
                                
                                <div class="col-md-6">
                                    <div class="media">
                                        <label>E-mail</label>
                                        <p>{{user.email}}</p>
                                    </div>
                                    <div class="media">
                                        <label>Username</label>
                                        <p>{{user.username}}</p>
                                    </div>
                                    <div class="media">
                                        <label>First Name</label>
                                        <p>{{user.first_name}}</p>
                                    </div>
                                    <div class="media">
                                        <label>Last Name</label>
                                        <p>{{user.last_name}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="changePassword">
                                    <div class="media2 d-flex">
                                        <label>Current password</label>
                                        <input type="password" class="form-control col-6" v-model="old_password" aria-describedby="passwordHelp">
                  
                                    </div>
                                    <div class="media2 d-flex">
                                        <label>New password</label>
                                         <input type="password" class="form-control col-6" v-model="password" aria-describedby="passwordHelp" id="popover-button-sync">
                                        
                                    </div>
                                    <b-popover :show="has_minimum_lenth || has_number || has_lowercase || has_uppercase || has_special" target="popover-button-sync" title="New password"
                                    :placement="'top'">
                                        <li v-if="!has_minimum_lenth" class="red">Password must has at least 8 characters</li>
                                            <li v-if="has_minimum_lenth" class="green">Password must has at least 8 characters</li>
                                            <li v-if="!has_lowercase" class="red">Password must has at least 1 lowercase</li>
                                            <li v-if="has_lowercase" class="green">Password must has at least 1 lowercase</li>
                                            <li v-if="!has_uppercase" class="red">Password must has at least 1 uppercase</li>
                                            <li v-if="has_uppercase" class="green">Password must has at least 1 uppercase</li>
                                            <li v-if="!has_number" class="red">Password must has at least 1 digit</li>
                                            <li v-if="has_number" class="green">Password must has at least 1 digit</li>
                                            <li v-if="!has_special" class="red">Password must has at least 1 special character</li>
                                            <li v-if="has_special" class="green">Password must has at least 1 special character</li>
                                    </b-popover>
                                    <!--<small v-if="has_minimum_lenth || has_number || has_lowercase || has_uppercase || has_special" id="passwordHelp" class="form-text text-muted" >
                                            <li v-if="!has_minimum_lenth" class="red">Password must has at least 8 characters</li>
                                            <li v-if="has_minimum_lenth" class="green">Password must has at least 8 characters</li>
                                            <li v-if="!has_lowercase" class="red">Password must has at least 1 lowercase</li>
                                            <li v-if="has_lowercase" class="green">Password must has at least 1 lowercase</li>
                                            <li v-if="!has_uppercase" class="red">Password must has at least 1 uppercase</li>
                                            <li v-if="has_uppercase" class="green">Password must has at least 1 uppercase</li>
                                            <li v-if="!has_number" class="red">Password must has at least 1 digit</li>
                                            <li v-if="has_number" class="green">Password must has at least 1 digit</li>
                                            <li v-if="!has_special" class="red">Password must has at least 1 special character</li>
                                            <li v-if="has_special" class="green">Password must has at least 1 special character</li>
                                        </small>-->
                                    <div class="media2 d-flex">
                                        <label>Repeat new password</label>
                                         <input type="password" class="form-control col-6" v-model="password2" aria-describedby="passwordHelp">
                                       
                                    </div>
                                    <small v-if="has_minimum_lenth || has_number || has_lowercase || has_uppercase || has_special" id="passwor2dHelp" class="form-text text-muted" >
                                            <li v-if="error" class="red">Password do not match</li>  
                                    </small>
                                    
                                </div>
                            </div>
                            <div class="d-flex">
                            <b-button variant="outline-primary" @click="changePassword=!changePassword" v-if="!changePassword">Change Password</b-button>
                            <div class="px-2">
                            <b-button variant="outline-success" v-if="changePassword" @click="ChangePasswordF">Save</b-button>
                            </div>
                            <b-button variant="outline-danger" v-if="changePassword" @click="changePassword=false">Cancel</b-button>
                            </div>
                            <b-alert show variant="primary" class="alert text-center" v-if="successAlert"> Success!</b-alert>
                            <b-alert show variant="danger" class="alert text-center" v-if="alert">Something went wrong try again!</b-alert>
                        </div>
                    </div>
                   
                </div>
                
            </div>
    </section>
</template>

<script>
import axios from "../axios-auth";
export default {
  components: {
  },
  data() {
  
    return {
    changePassword:false,
    user:null,
    group_name:null,
    old_password: '',
    password: null,
    password2: null,
    successAlert:false,
    alert: false,
    username_new: null,
    email_new:'',
    has_minimum_lenth: false,
    has_number: false,
    has_lowercase: false,
    has_uppercase: false,
    has_special: false,
    error:false,
    success:false
    }
  },
  created(){
       var user_data = localStorage.getItem('user')
       //var v=this
       this.user=JSON.parse(user_data)
  },
   watch:{
            password(){
              
                this.has_minimum_lenth = (this.password.length >= 8);
                this.has_number    = /\d/.test(this.password);
                this.has_lowercase = /[a-z]/.test(this.password);
                this.has_uppercase = /[A-Z]/.test(this.password);
                this.has_special   = /[!@#/$%/^/&*/)/(+=._-]/.test(this.password);
                this.success=this.has_minimum_lenth && this.has_number && this.has_lowercase && this.has_uppercase && this.has_special
                if(this.password!== this.password2 && this.password2!==null){
                this.error=true 
                }else{
              this.error=false
            }
               
            },
            password2(){
              if(this.password!== this.password2 && this.password!==null){
                this.error=true 
            }else{
              this.error=false
            }
        },
  },
  methods:{
    ChangePasswordF(){
        //console.log(this.$store.getters.user[0].id)
        if(!this.error && this.success){
        const id=this.user.id
        //console.log(this.old_password)
        //console.log(this.password)
        //console.log(this.password2)
        //const token = localStorage.getItem('token')
        const token = localStorage.getItem('token')

        axios.put('/change_password/'+id+'/', {
          password: this.password,
          password2: this.password2,
          old_password: this.old_password,
        },{ headers: { Authorization: `Bearer ${token}` } })
          .then(()=>{
            this.successAlert=true
            this.old_password=''
            this.password=''
            this.password2=''
            setTimeout(()=>this.successAlert=false,2000);
            this.changePassword=false
          }).catch(() => {
          this.alert=true
          this.old_password=''
          this.password=''
          this.password2=''
          setTimeout(()=>this.alert=false,2000);
          })
        }else{
          this.alert=true
          setTimeout(()=>this.alert=false,2000);
        }

    }
    }
}
</script>



<style >
@import '../assets/css/profile.css';
.red{
  color:rgb(255,0,0)!important
}
.green{
  color:#12a024!important
}
</style>